import React from "react";

function WrapperSideBar({ children, title, ...props }) {
  return (
    <div {...props} className=" flex flex-col py-3 space-y-6  rounded-lg">
      <h1 className=" text-lg font-semibold">{title}</h1>
      {children}
    </div>
  );
}

export default WrapperSideBar;
