import React from "react";
import { useSelector } from "react-redux";

export function ItemSide({ children }) {
  return (
    <div className="  gap-3 flex *:shrink-0 *:whitespace-break-spaces w-full   items-center ">
      {children}
    </div>
  );
}
export function ItemsEducation({ data }) {
  const color = useSelector((state) => state.theme);

  return (
    <div className="  grid  gap-1 grid-cols-12">
      <h1
        className={`text-sm text-[${color.fontColor}] font-semibold col-span-9`}
      >
        {data.degree}
      </h1>
      <span
        className={`col-span-3 text-xs font-medium text-end text-[${color.fontColor}]  `}
      >
        {[data.startDate, data.endDate].join(" - ")}
      </span>
      <p className="text-xs font-medium   col-span-full">
        {[data.institution, data.location].join(",")}
      </p>

      {data.description && (
        <p className="  font-light col-span-full text-sm">{data.description}</p>
      )}
    </div>
  );
}
export function ItemsExperience({ data }) {
  const color = useSelector((state) => state.theme);

  return (
    <div className="  grid  gap-1 grid-cols-12">
      <h1
        className={`text-sm text-[${color.fontColor}] font-semibold col-span-9`}
      >
        {data.title}
      </h1>
      <span
        className={`col-span-3 text-xs font-medium text-end text-[${color.fontColor}]  `}
      >
        {[data.startDate, data.endDate].join(" - ")}
      </span>
      <p className="text-xs font-medium   col-span-full">
        {" "}
        {[data.company, data.location].join(",")}
      </p>

      {data.description && (
        <p className="  font-light col-span-full text-sm">{data.description}</p>
      )}
    </div>
  );
}
