import React, { useEffect, useState, useRef } from "react";

// Liste des balises void HTML
const voidElements = [
  "area",
  "base",
  "br",
  "col",
  "embed",
  "hr",
  "img",
  "input",
  "link",
  "meta",
  "param",
  "source",
  "track",
  "wbr",
];

// Fonction pour convertir une chaîne CSS en un objet JavaScript
const parseStyleString = (styleString) => {
  const styleObject = {};
  const styleArray = styleString.split(";");

  styleArray.forEach((style) => {
    if (style.trim()) {
      const [property, value] = style.split(":");
      const camelCaseProperty = property
        .trim()
        .replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
      styleObject[camelCaseProperty] = value.trim();
    }
  });

  return styleObject;
};

// Fonction pour récupérer les attributs de l'élément DOM
const getAttributes = (element) => {
  const attributes = {};

  for (let attr of element.attributes) {
    // Si l'attribut est 'style', on le convertit en objet
    if (attr.name === "style") {
      attributes.style = parseStyleString(attr.value);
    } else {
      attributes[attr.name] = attr.value;
    }
  }

  return attributes;
};

// Fonction pour convertir un élément DOM en JSX
const convertDomToJsx = (element) => {
  if (!element) return null;

  const tagName = element.tagName.toLowerCase();
  const children = Array.from(element.childNodes);

  // Vérifier si c'est un élément void
  if (voidElements.includes(tagName)) {
    return React.createElement(tagName, {
      key: Math.random(),
      ...getAttributes(element),
    });
  }

  // Recréer l'arborescence en JSX pour les éléments non void
  return React.createElement(
    tagName,
    { key: Math.random(), ...getAttributes(element) }, // Propriétés de l'élément
    children.map(
      (child, index) =>
        child.nodeType === Node.TEXT_NODE
          ? child.textContent // Si c'est un texte, on retourne le texte
          : convertDomToJsx(child) // Sinon, on continue de recréer l'élément JSX
    )
  );
};

export const useOverflowCheck = (wrapperRefs, options) => {
  const [overflowData, setOverflowData] = useState({});
  const { templateOne, deps } = options;

  useEffect(() => {
    const checkOverflow = () => {
      const newOverflowData = {};

      wrapperRefs.forEach((wrapperRef, index) => {
        const wrapper = wrapperRef.current;
        if (wrapper) {
          const wrapperRect = wrapper.getBoundingClientRect();
          const overflowElements = [];
          const jsxElements = [];

          const checkChildOverflow = (element) => {
            const childRect = element.getBoundingClientRect();

            if (childRect.bottom > wrapperRect.bottom) {
              const children = Array.from(element.children);

              if (children.length > 0) {
                let allChildrenOverflow = true;

                children.forEach((child) => {
                  const childRect = child.getBoundingClientRect();
                  if (childRect.bottom <= wrapperRect.bottom) {
                    allChildrenOverflow = false;
                  }
                });

                if (allChildrenOverflow) {
                  overflowElements.push(element);

                  // Convertir l'élément DOM en JSX et l'ajouter
                  jsxElements.push(convertDomToJsx(element));
                } else {
                  children.forEach((child) => {
                    checkChildOverflow(child);
                  });
                }
              } else {
                overflowElements.push(element);
                // Convertir l'élément DOM en JSX
                jsxElements.push(convertDomToJsx(element));
              }
            }
          };

          // Vérification des enfants pour wrapper principal ou template
          if (index !== 1) {
            Array.from(wrapper.children).forEach((child) => {
              checkChildOverflow(child);
            });
          } else if (templateOne && index === 1) {
            Array.from(wrapper.children[1].children[0].children).forEach(
              (child) => {
                checkChildOverflow(child);
              }
            );
          }

          newOverflowData[`wrapper${index}`] = {
            isOverflowing: overflowElements.length > 0,
            elements: overflowElements,
            jsxElements, // Les éléments en JSX
          };
        }
      });

      setOverflowData(newOverflowData);
    };

    checkOverflow();

    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [templateOne, deps]);

  return overflowData;
};
