import React, { useEffect, useRef, useState } from "react";
import { classNames } from "../../utils/ helpers";

export function WrapperElementSide({ children, title, ...props }) {
  return (
    <div {...props}>
      <h1 className="font-semibold text-xl text-[#336C96]">{title}</h1>
      <hr className="h-1 my-3  bg-[#336C96]" />

      {children}
    </div>
  );
}

export function WrapperMain({
  children,
  title,
  gap = 0,
  space = true,
  ...props
}) {
  return (
    <div {...props}>
      <h1 className="font-semibold text-2xl text-[#336C96]">{title}</h1>
      <hr className="my-3 bg-[#336C96]" />
      <div
        className={classNames("flex  flex-col", space ? "space-y-4" : "")}
        style={{ gap: gap }}
      >
        {children}
      </div>
    </div>
  );
}
