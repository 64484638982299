// TypeList.jsx

import React from "react";
import TextField from "./TextField";
import ButtonsBottom from "./Button";

export function TypeList({
  data,
  onUpdate,
  onSave,
  onCancel,
  isEditing,
  index,
}) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onUpdate({ ...data, [name]: value });
  };

  return (
    <div className="p-2 flex flex-col items-center gap-2">
      <TextField
        name="item"
        value={data.item}
        onChange={handleChange}
        placeholder="Élément de la liste"
        className="w-full p-2 border rounded"
      />
      <ButtonsBottom onSave={onSave} onCancel={onCancel} />
    </div>
  );
}
