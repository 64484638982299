import React from "react";
import A4Wrapper from "../Preview/A4Wrapper";

function MultiPageWrapper({ templateA, templateB, overflow, ...props }) {
  return (
    <div {...props}>
      <A4Wrapper>{templateA}</A4Wrapper>

      {overflow.wrapper0?.isOverflowing && <A4Wrapper>{templateB}</A4Wrapper>}
    </div>
  );
}

export default MultiPageWrapper;
