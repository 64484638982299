import React from "react";

function TextAreaField({ className, ...props }) {
  return (
    <textarea
      {...props}
      className={"p-4 text-sm rounded-lg bg-gray-100 w-full"}
      type="text"
    />
  );
}

export default TextAreaField;
