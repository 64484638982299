// PersonalInformation.jsx
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updatePersonalInfo } from "../../features/cvSlice";
import TextField from "./TextField";

export function PersonalInformation() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cv.sections.personalinfo);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updatePersonalInfo({ [name]: value }));
  };

  return (
    <div className="p-2 flex flex-col gap-2">
      <div className="grid grid-cols-full gap-4">
        <TextField
          name="name"
          value={data.name || ""}
          onChange={handleChange}
          placeholder="Nom complet"
        />
      </div>
      <div className="grid grid-cols-full gap-4">
        <TextField
          name="title"
          value={data.title || ""}
          onChange={handleChange}
          placeholder="Titre"
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <TextField
          name="phone"
          value={data.phone || ""}
          onChange={handleChange}
          placeholder="Téléphone"
          className="w-full p-2 border rounded"
        />
        <TextField
          name="email"
          value={data.email || ""}
          onChange={handleChange}
          placeholder="Adresse email"
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="grid grid-cols-1 gap-4">
        <TextField
          name="address"
          value={data.address || ""}
          onChange={handleChange}
          placeholder="Votre adresse"
          className="w-full p-2 border rounded"
        />
      </div>
    </div>
  );
}
