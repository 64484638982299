import React from "react";
import WrapperSpacing from "../../../Element/WrapperSpacing";

function MojaB({ overflow }) {
  return (
    <div className="grid-cols-12 grid size-full ">
      <div className=" col-span-4 pb-2  overflow-hidden px-6 bg-[#E2EAEF]   ">
        <WrapperSpacing pt={20} gap={20}>
          {overflow?.wrapper1.jsxElements}
        </WrapperSpacing>
      </div>
      <div className=" p-4 col-span-8 h-[98%] overflow-hidden ">
        {overflow?.wrapper0.jsxElements}
      </div>
    </div>
  );
}

export default MojaB;
