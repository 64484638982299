// Accordion.jsx
import React, { useState } from "react";

function Accordion({ title, children, child, isOpen, onToggle }) {
  return (
    <div className="relative">
      {!isOpen && child}
      <button
        onClick={onToggle}
        className="w-full flex justify-between items-center py-4 text-left"
      >
        <span className="text-xl font-bold text-gray-800">{title}</span>
        <svg
          className={`w-5 h-5 transform transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <div
        className={`transition-all duration-300 ease-in-out overflow-hidden ${
          isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <div className="py-2 flex flex-col gap-4">{children}</div>
      </div>
    </div>
  );
}

export default Accordion;
