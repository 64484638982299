import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div
      id="header"
      className="  items-center p-4 grid grid-cols-12 bg-gray-100/20 "
    >
      <div className=" col-span-2">
        <h1 className="   font-semibold text-3xl text-transparent bg-clip-text bg-gradient-to-r  from-teal-800 to-violet-900 ">
          ResumeRealm
        </h1>
      </div>
      <div className="flex gap-10  justify-center font-custom1 col-span-8 flex-grow-0 text-lg ">
        <Link to={"/templates"}>Modèles de CV</Link>
        <Link to={"/price"}>Tarification</Link>
        <Link to={"/faq"}>FAQ</Link>
      </div>
      <div className=" col-span-2 flex justify-end">
        <button className=" bg-indigo-800 font-semibold p-2 px-8 font-custom1 hover:bg-indigo-700 text-indigo-50 rounded-lg">
          Créer
        </button>
      </div>
    </div>
  );
}

export default Header;
