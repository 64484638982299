// TypeDescription.jsx

import React from "react";
import TextAreaField from "./TextAreaField";

export function TypeDescription({
  data,
  onUpdate,
  onSave,
  onCancel,
  isEditing,
  index,
}) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onUpdate({ ...data, [name]: value });
  };

  return (
    <div className="p-2">
      <TextAreaField
        name="description"
        value={data.description}
        onChange={handleChange}
        placeholder="Description"
      />
    </div>
  );
}
