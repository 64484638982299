// SectionType.jsx

import React from "react";
import { useDispatch } from "react-redux";

import {
  updatePersonalInfo,
  updateSectionItem,
  updateSingleSection,
} from "../../features/cvSlice";
import { PersonalInformation } from "./Personalnformation";
import { TypeList } from "./TypeList";
import { TypeEducation } from "./TypeEducation";
import { TypeExperience } from "./TypeExperience";
import { Skill } from "./SkillType";
import { TypeDescription } from "./TypeDescription";

function SectionType({
  type = "personalinfo",
  index,
  data,
  onSave,
  onCancel,
  isEditing,
  onUpdate,
  onRemove,
}) {
  const dispatch = useDispatch();

  const handleUpdate = (updatedData) => {
    onUpdate(updatedData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...data, [name]: value };
    handleUpdate(updatedData);
  };

  const handleRemove = () => {
    if (type !== "personalinfo") {
      onRemove();
    }
  };

  const components = {
    personalinfo: PersonalInformation,
    list: TypeList,
    education: TypeEducation,
    experience: TypeExperience,
    skills: Skill,
    description: TypeDescription,
    languages: Skill,
    interests: TypeList,
  };

  const ComponentToRender = components[type];

  return (
    <div className="flex flex-col gap-4 border rounded p-2">
      {ComponentToRender ? (
        <ComponentToRender
          data={data}
          onSave={onSave}
          onCancel={onCancel}
          isEditing={isEditing}
          index={index}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
        />
      ) : (
        <p>Type de section inconnu : {type}</p>
      )}
    </div>
  );
}

export default SectionType;
