// TypeExperience.jsx

import React from "react";
import TextField from "./TextField";
import TextAreaField from "./TextAreaField";
import ButtonsBottom from "./Button";

export function TypeExperience({
  data,
  onUpdate,
  onSave,
  onCancel,
  isEditing,
  index,
}) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onUpdate({ ...data, [name]: value });
  };

  return (
    <div className="grid grid-cols-4 gap-4 p-2">
      <div className="col-span-full">
        <TextField
          name="title"
          value={data.title}
          onChange={handleChange}
          placeholder="Poste"
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="col-span-2">
        <TextField
          name="company"
          value={data.company}
          onChange={handleChange}
          placeholder="Entreprise"
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="col-span-2">
        <TextField
          name="location"
          value={data.location}
          onChange={handleChange}
          placeholder="Lieu"
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="col-span-2">
        <TextField
          name="startDate"
          value={data.startDate}
          onChange={handleChange}
          placeholder="Date de début"
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="col-span-2">
        <TextField
          name="endDate"
          value={data.endDate}
          onChange={handleChange}
          placeholder="Date de fin"
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="col-span-full">
        <TextAreaField
          name="description"
          value={data.description}
          onChange={handleChange}
          placeholder="Description"
          className="w-full p-2 border rounded"
        />
      </div>
      <ButtonsBottom onSave={onSave} onCancel={onCancel} />
    </div>
  );
}

export default TypeExperience;
