import React, { useRef, useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const A4Wrapper = ({ children }) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isOverflow, setIsOverflow] = useState(false); // État pour suivre le débordement
  const ref = useRef();

  // Fonction pour ajuster le zoom
  const handleZoomIn = () => setZoomLevel(zoomLevel + 0.1);
  const handleZoomOut = () => setZoomLevel(zoomLevel - 0.1);
  const handleResetZoom = () => setZoomLevel(1);

  // Fonction pour exporter en PDF
  const exportAsPdf = () => {
    const input = ref.current;
    const pdf = new jsPDF("p", "mm", "a4");

    const scale = 3;
    html2canvas(input, { scale, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("exported-html.pdf");
    });
  };

  return (
    <div className="flex flex-col items-center break-after-page ">
      <div
        className="border shadow-lg bg-white overflow-hidden a4-wrapper"
        ref={ref}
        style={{
          transform: `scale(${zoomLevel})`,
          width: "210mm",
          height: "297mm",
          transformOrigin: "top left",
          pageBreakBefore: "always",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default A4Wrapper;
