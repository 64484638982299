import React from "react";
import { useSelector } from "react-redux";

function ListElement({ children }) {
  const color = useSelector((state) => state.theme);

  return (
    <div className="  gap-3 flex *:shrink-0 *:whitespace-break-spaces w-full  text-sm  items-center">
      <div className={`size-4 bg-[${color.fontColor}]`} />
      {children}
    </div>
  );
}

export default ListElement;
