// SkillType.jsx

import React from "react";
import TextAreaField from "./TextAreaField";
import ButtonsBottom from "./Button";

export function Skill({ data, onUpdate, onSave, onCancel, isEditing, index }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onUpdate({ ...data, [name]: value });
  };

  return (
    <div className="p-2 flex flex-col gap-4">
      <TextAreaField
        name="name"
        value={data.name}
        onChange={handleChange}
        placeholder="Compétence"
      />
      <div className="px-20 py-4 w-full relative flex items-center">
        <span className="absolute left-2 text-sm top-1/2 -translate-y-1/2">
          Niveau
        </span>
        <input
          name="level"
          type="range"
          min="0"
          step={1}
          max="5"
          value={data.level}
          onChange={handleChange}
          className="w-full h-2 appearance-none  bg-gray-100 rounded-lg"
        />
        <span className="absolute right-1 text-sm top-1/2 -translate-y-1/2">
          {data.level}
        </span>
      </div>
      <ButtonsBottom onSave={onSave} onCancel={onCancel} />
    </div>
  );
}
