import React from "react";
import { classNames } from "../../utils/ helpers";

function TextField({ className, ...props }) {
  return (
    <input
      {...props}
      className={classNames("p-4 text-sm rounded-lg bg-gray-100 w-full")}
      type="text"
    />
  );
}

export default TextField;
