import React from "react";

function BottomSettings({ children, ...props }) {
  return (
    <div
      {...props}
      className=" fixed  bottom-4  flex gap-4 right-20  w-1/2  justify-end p-2 rounded-lg bg-gray-100"
    >
      {children}
    </div>
  );
}

export default BottomSettings;
