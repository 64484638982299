// CVEditorPage.jsx
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CVPreview from "../component/Preview/CVPreview";
import SideBar from "../component/Element/SideBar";
import WrapperSideBar from "../component/Element/wrapperSideBar";
import SectionElement from "../component/Element/SectionElement";
import BottomSettings from "../component/Element/BottomSettings";

const CVEditorPage = () => {
  const sectionsOrder = useSelector((state) => state.cv.sectionsOrder);

  // État local pour suivre l'index de l'accordéon ouvert
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  return (
    <div className="grid grid-cols-2 h-[90vh] bg-slate-50 overflow-hidden">
      <SideBar className="mx-auto max-w-2xl  w-full p-4 flex flex-col space-y-8 overflow-y-scroll h-[90vh]">
        <WrapperSideBar>
          {sectionsOrder.map((sectionType, index) => {
            const sectionProps = getSectionProps(sectionType);
            const isOpen = openAccordionIndex === index;

            const handleAccordionToggle = () => {
              setOpenAccordionIndex(isOpen ? null : index);
            };

            return (
              <SectionElement
                key={sectionType}
                title={sectionProps.title}
                type={sectionProps.type}
                hasMore={sectionProps.hasMore}
                index={index}
                totalSections={sectionsOrder.length}
                isOpen={isOpen}
                onAccordionToggle={handleAccordionToggle}
              />
            );
          })}
        </WrapperSideBar>
      </SideBar>

      <CVPreview className="h-[90vh] pb-16 w-full  relative overflow-y-scroll p-4" />
    </div>
  );
};

export default CVEditorPage;

function getSectionProps(sectionType) {
  switch (sectionType) {
    case "personalinfo":
      return {
        title: "Informations Personnelles",
        type: "personalinfo",
        hasMore: false,
      };
    case "description":
      return { title: "Profil", type: "description", hasMore: false };
    case "education":
      return { title: "Formation", type: "education", hasMore: true };
    case "experience":
      return { title: "Expérience", type: "experience", hasMore: true };
    case "skills":
      return { title: "Compétences", type: "skills", hasMore: true };
    case "languages":
      return { title: "Langues", type: "languages", hasMore: true };
    case "interests":
      return { title: "Centres d'intérêt", type: "interests", hasMore: true };
    default:
      return { title: sectionType, type: sectionType, hasMore: false };
  }
}
