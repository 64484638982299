// cvSlice.js

import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  sectionsOrder: [
    "personalinfo",
    "description",
    "education",
    "experience",
    "skills",
    "languages", // Langues
    "interests", // Centres d'intérêt
  ],
  sections: {
    personalinfo: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
    },
    description: {
      description: "",
    },
    education: [],
    experience: [],
    skills: [],
    languages: [],
    interests: [],
  },
  // ... autres états
};

const savedState = JSON.parse(localStorage.getItem("cvData"));

const initialState = savedState
  ? { ...defaultState, ...savedState }
  : defaultState;

const cvSlice = createSlice({
  name: "cv",
  initialState,
  reducers: {
    // Ajouter un élément à une section
    addSectionItem: (state, action) => {
      const { sectionType, item } = action.payload;
      state.sections[sectionType].push(item);
      console.log(`Added item to ${sectionType}:`, item);
      localStorage.setItem("cvData", JSON.stringify(state));
    },
    // Déplacer une section vers le haut
    moveSectionUp: (state, action) => {
      const index = action.payload;
      if (index > 0) {
        const newSectionsOrder = [...state.sectionsOrder];
        [newSectionsOrder[index - 1], newSectionsOrder[index]] = [
          newSectionsOrder[index],
          newSectionsOrder[index - 1],
        ];
        state.sectionsOrder = newSectionsOrder;
        localStorage.setItem("cvData", JSON.stringify(state));
        console.log(`Moved section up: ${newSectionsOrder[index - 1]}`);
      }
    },
    // Déplacer une section vers le bas
    moveSectionDown: (state, action) => {
      const index = action.payload;
      if (index < state.sectionsOrder.length - 1) {
        const newSectionsOrder = [...state.sectionsOrder];
        [newSectionsOrder[index + 1], newSectionsOrder[index]] = [
          newSectionsOrder[index],
          newSectionsOrder[index + 1],
        ];
        state.sectionsOrder = newSectionsOrder;
        localStorage.setItem("cvData", JSON.stringify(state));
        console.log(`Moved section down: ${newSectionsOrder[index + 1]}`);
      }
    },
    // Mettre à jour un élément dans une section
    updateSectionItem: (state, action) => {
      const { sectionType, index, item } = action.payload;
      console.log(`Updating section: ${sectionType}, index: ${index}`, item);

      if (Array.isArray(state.sections[sectionType])) {
        if (
          typeof index === "number" &&
          index >= 0 &&
          index < state.sections[sectionType].length
        ) {
          state.sections[sectionType][index] = {
            ...state.sections[sectionType][index],
            ...item,
          };
          console.log(`Updated item at index ${index} in ${sectionType}`);
        } else {
          console.error(
            `Invalid index ${index} for sectionType ${sectionType}`
          );
        }
      } else {
        state.sections[sectionType] = {
          ...state.sections[sectionType],
          ...item,
        };
        console.log(`Updated section ${sectionType}:`, item);
      }

      // Sauvegarde dans localStorage
      localStorage.setItem("cvData", JSON.stringify(state));
    },
    // Supprimer un élément d'une section
    removeSectionItem: (state, action) => {
      const { sectionType, index } = action.payload;
      if (Array.isArray(state.sections[sectionType])) {
        if (
          typeof index === "number" &&
          index >= 0 &&
          index < state.sections[sectionType].length
        ) {
          state.sections[sectionType].splice(index, 1);
          console.log(`Removed item at index ${index} from ${sectionType}`);
        } else {
          console.error(
            `Invalid index ${index} for sectionType ${sectionType}`
          );
        }
      }
      localStorage.setItem("cvData", JSON.stringify(state));
    },
    // Mettre à jour les informations personnelles
    updatePersonalInfo: (state, action) => {
      state.sections.personalinfo = {
        ...state.sections.personalinfo,
        ...action.payload,
      };
      console.log("Updated personal info:", action.payload);
      localStorage.setItem("cvData", JSON.stringify(state));
    },
    // Mettre à jour une section unique (non tableau)
    updateSingleSection: (state, action) => {
      console.log("array");
      const { sectionType, data } = action.payload;
      state.sections[sectionType] = {
        ...state.sections[sectionType],
        ...data,
      };
      console.log(`Updated single section ${sectionType}:`, data);
      localStorage.setItem("cvData", JSON.stringify(state));
    },
  },
});

export const {
  addSectionItem,
  moveSectionUp,
  moveSectionDown,
  updateSectionItem,
  removeSectionItem,
  updatePersonalInfo,
  updateSingleSection,
} = cvSlice.actions;
export default cvSlice.reducer;
