import { createSlice } from "@reduxjs/toolkit";

const initialState = JSON.parse(localStorage.getItem("themeData")) || {
  font: "Arial",
  color: "#000000",
  fontColor: "#336C96",
  spacing: 10,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    updateCustomization: (state, action) => {
      const { font, color, spacing } = action.payload;
      state.font = font || state.font;
      state.color = color || state.color;
      state.spacing = spacing || state.spacing;
      localStorage.setItem("themeData", JSON.stringify(state));
    },
  },
});

export const { updateCustomization } = themeSlice.actions;
export default themeSlice.reducer;
