import React from "react";
import { useSelector } from "react-redux";
import { classNames } from "../../utils/ helpers";

export function TextSide({ children, ...props }) {
  const color = useSelector((state) => state.theme);

  return (
    <div className="w-full">
      <h1
        className={classNames(
          "text-sm     break-words  font-medium",
          `text-[${color.fontColor}]`
        )}
        {...props}
      >
        {children}
      </h1>
    </div>
  );
}
