import React from "react";
import ReactDOM from "react-dom/client";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./landing-page.js/layout.js";
import "./assets/index.css";
import { Provider } from "react-redux";

import { store } from "./store/store.js";
import CVEditorPage from "./pages/CVEditorPage.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,

    children: [
      /*  {
        index: true,
        element: <About />,
      },
      {
        path: "templates",
        element: <Templates />,
      }, */
      {
        index: true,
        element: <CVEditorPage />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
