import React from "react";

function ButtonsBottom({ onSave, onCancel }) {
  return (
    <div className="col-span-full w-full flex justify-end space-x-2">
      <button
        className="border border-dashed  bg-red-50 text-red-700 p-2 rounded"
        onClick={onCancel}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </button>
      <button
        onClick={onSave}
        className="border border-dashed p-2 rounded bg-gray-50 text-green-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m4.5 12.75 6 6 9-13.5"
          />
        </svg>
      </button>
    </div>
  );
}

export default ButtonsBottom;
