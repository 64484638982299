import React from "react";
import { classNames } from "../../utils/ helpers";

function WrapperSpacing({ ref, pt = 0, children, align = "col", gap = 0 }) {
  return (
    <div
      ref={ref}
      style={{
        gap: gap,
        paddingTop: pt,
      }}
      className={classNames("flex", align === "col" ? "flex-col" : "flex-row")}
    >
      {children}
    </div>
  );
}

export default WrapperSpacing;
